<template>
    <div class="index">
        <router-view />
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less"></style>